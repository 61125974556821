/**
 * Written by Ronghua
 */

import React from "react";
import "../../assets/styles/paragraph.css"; 
import MarkText from "../../ultilities/MarkText";
import useScreenSize from "../../ultilities/useScreenSize.js";


export default function Paragraph ({
  isSpanish, 
  markedText, 
  text, 
}) {
  const screenSize = useScreenSize();

    return (
      <div className="paragraph-parent">
        <div className="paragraph-background">
          <div className="paragraph-text"> 
            {markedText ? (
              <div style={
                isSpanish
                  ? { fontSize: screenSize.width * 0.026 + "px" }
                  : {}
              }
              >
                <MarkText
                  isSpanish={isSpanish}
                  sentence={text}
                  textAndStyle={markedText}
                />
              </div>
              ) : (
                <div>{text}</div>
            )}
          </div>
        </div>
      </div>
    ); 
}