import { useState, useEffect } from 'react';
import useSound from "use-sound";

import { BACKEND_LINK } from "../../ultilities/global.links";
import "../../assets/styles/timer-click-styles.css";
import timerIcon from "../../assets/icons/UI_Timer_Icon_V2 1.png";
import buttonClick from "../../assets/audio/UI_Button-Click-1.mp3";

const TimerClick = ({ options, setSelectorReturnValue }) => {
  const [clickedPapers, setClickedPapers] = useState(new Set());
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [playButtonClick] = useSound(buttonClick, { volume: 0.25 });

  // Timer logic
  useEffect(() => {
    if (isActive && timer < 60) {
      const interval = setInterval(() => {
        setTimer(time => time + 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer >= 60) {
      setIsActive(false);
      setSelectorReturnValue("changeFooterText");
    } 
  }, [isActive, timer, setSelectorReturnValue]);

  // only change footer text when all papers are clicked and if the activity is still active
  useEffect(() => {
    if (isActive && clickedPapers.size === options.length - 1) {
      setSelectorReturnValue("changeFooterText");
      setIsActive(false);
    }
  }, [isActive, clickedPapers, options.length, setSelectorReturnValue]);

  const handlePaperClick = (index) => {
    playButtonClick();
    setClickedPapers(prev => {
      const newSet = new Set(prev);
      newSet.add(index);
      return newSet;
    });
  };

  const formatTime = (seconds) => {
    return `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;
  };

  return (
    <div className="timer-click-container">
      <div className="timer">
        <img 
          src={timerIcon}
          alt="Timer icon"
          className="timer-icon"
        />
        <span className="timer-text">{formatTime(timer)}</span>
      </div>
      <div className="papers-container">
        {options.map((paper, index) => (
          <img
            key={index}
            className={isActive ? "" : "inactive-img"}
            src={BACKEND_LINK + (clickedPapers.has(index) ? "img/P_Homework01.png" : "img/P_Homework00.png")}
            alt="Homework paper"
            style={{
              position: 'absolute',
              top: `calc(var(--screen-height) * (${paper.top} / var(--base-height)))`,
              left: `calc(var(--screen-width) * (${paper.left} / var(--base-width)) * .75)`,
              width: `calc(var(--screen-width) * .75 * (${paper.width} / var(--base-width)))`,
              height: `calc(var(--screen-height) * .85 * (${paper.height} / var(--base-height)))`,
              cursor: 'pointer'
            }}
            onClick={() => handlePaperClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TimerClick;
