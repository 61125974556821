import React, { useState, useEffect } from 'react';
import { DndContext, useDraggable, useDroppable, DragOverlay } from '@dnd-kit/core';
import useSound from 'use-sound';
import '../../assets/styles/prefix-matching-styles.css';
import { CORRECT_SOUND } from "../../ultilities/global.links.js";

function DraggablePrefix({ id, children }) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: id,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    zIndex: 1000,
  } : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} className="draggable-prefix-wrapper">
      {children}
    </div>
  );
}

function DroppableWord({ id, children, isOccupied }) {
  const { setNodeRef } = useDroppable({
    id: id,
    disabled: isOccupied,
  });
  
  return (
    <div ref={setNodeRef} className="word-dropzone">
      {children}
    </div>
  );
}

function PrefixMatching({ clickables, setSelectorReturnValue, isSpanish, behavior }) {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [combinedWords, setCombinedWords] = useState(false);

  const [playCorrectSound] = useSound(CORRECT_SOUND, {
    volume: 0.25,
  });
  
  useEffect(() => {
    const newItems = clickables.map((item, index) => ({
      id: `prefix-${index}`,
      location: 'outside',
      word: isSpanish ? item.textEs : item.text,
      prefix: item.prefix,
      baseWord: (isSpanish ? item.textEs : item.text).toLowerCase()
    }));
    setItems([...newItems]);
  }, [clickables, isSpanish]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (over && over.id !== 'outside') {
      const targetWord = items.find(item => item.baseWord === over.id);
      if (targetWord) {
        setItems(prevItems => {
          const newItems = prevItems.map(item => {
            if (item.id === active.id) {
              return { ...item, location: over.id };
            }
            if (item.location === over.id) {
              return { ...item, location: 'outside' };
            }
            return item;
          });
          
          if (newItems.every(item => item.location !== 'outside')) {
            playCorrectSound();
            setSelectorReturnValue(behavior === "changeFooterText" ? "changeFooterText" : "correctAnswer");
            setCombinedWords(true);
          }
          
          return newItems;
        });
      }
    }
    
    setActiveId(null);
  };

  const handlePrefixClick = (itemId) => {
    setItems(prevItems => prevItems.map(item => 
      item.id === itemId ? { ...item, location: 'outside' } : item
    ));
  };

  const renderPrefix = (item) => (
    <DraggablePrefix key={item.id} id={item.id}>
      <div className="prefix-item">{item.prefix}</div>
    </DraggablePrefix>
  );

  const renderPlaceholder = (key) => (
    <div key={key} className="placeholder-item prefix-item">
      {/* Transparent placeholder */}
    </div>
  );

  return (
    <div className="prefix-matching-container">
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className="prefixes">
          {!combinedWords && items.map(item => 
            item.location === 'outside' ? renderPrefix(item) : renderPlaceholder(item.id)
          )}
        </div>
        <div className="base-words">
          {items.map((item, index) => (
            <DroppableWord 
              key={index}
              id={item.baseWord}
              isOccupied={items.some(i => i.location === item.baseWord)}
            >
              <div className="word-container">
                {!combinedWords ? (
                  <>
                    <div className="prefix-slot">
                      {items.find(i => i.location === item.baseWord) ? (
                        <div 
                          className="prefix-item clickable" 
                          onClick={() => handlePrefixClick(items.find(i => i.location === item.baseWord).id)}
                        >
                          {items.find(i => i.location === item.baseWord)?.prefix}
                        </div>
                      ) : (
                        <div className="prefix-placeholder"></div>
                      )}
                    </div>
                    <div className="base-word">{item.word}</div>
                  </>
                ) : (
                  <div className="base-word combined">
                    {items.find(i => i.location === item.baseWord)?.prefix}{item.word}
                  </div>
                )}
              </div>
            </DroppableWord>
          ))}
        </div>
        <DragOverlay dropAnimation={null}>
          {activeId ? (
            <div className="prefix-item">
              {items.find(item => item.id === activeId)?.prefix}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

export default PrefixMatching;
