import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { useEffect, useState } from "react";
import useSound from "use-sound";

import "../../assets/styles/timer-click-and-drag-styles.css";
import timerIcon from "../../assets/icons/UI_Timer_Icon_V2 1.png";

import { BACKEND_LINK } from "../../ultilities/global.links";

function ImageDraggableItem({ id, imageSrc, style }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });
  const transformStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1000,
      }
    : undefined;

  return (
    <img
      ref={setNodeRef}
      style={{
        ...style,
        ...transformStyle,
      }}
      {...listeners}
      {...attributes}
      src={imageSrc}
      alt={imageSrc}
    />
  );
}

function ImageDroppableBucket({ id, imageSrc }) {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <img ref={setNodeRef} src={imageSrc} alt="" className="bucket-dropzone" />
  );
}

function TimerImageDragDrop({ options, sources, setSelectorReturnValue }) {
  const [items, setItems] = useState([]);
  const [draggedItems, setDraggedItems] = useState(new Set());
  const [activeId, setActiveId] = useState(null);
  const [bucketImageIndex, setBucketImageIndex] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [playDropSound] = useSound(BACKEND_LINK + "audio/Cloth-Impact.mp3", {
    volume: 0.25,
    loop: false,
  });

  useEffect(() => {
    const newItems = options.map((item, index) => ({
      id: `item-${index}`,
      location: "outside",
      imageSrc: item.media,
    }));
    setItems(newItems);
  }, [options]);

  useEffect(() => {
    if (isActive && timer < 60) {
      const interval = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isActive, timer]);

  useEffect(() => {
    if (timer >= 60) {
      setIsActive(false);
      setSelectorReturnValue("changeFooterText");
    }
  }, [timer, setSelectorReturnValue]);

  // Format time to MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const itemStyles = {
    "item-1": {
      top: "calc(var(--screen-height) * 0.12)",
      left: "calc(var(--screen-width) * 0.3)",
      width: "calc(var(--screen-width) * 0.22)",
    },
    "item-2": {
      top: "calc(var(--screen-height) * 0.025)",
      left: "calc(var(--screen-width) * 0.52)",
      width: "calc(var(--screen-width) * 0.16)",
    },
    "item-4": {
      top: "calc(var(--screen-height) * 0.14)",
      left: "calc(var(--screen-width) * 0.7)",
      width: "calc(var(--screen-width) * 0.21)",
    },
    "item-3": {
      top: "calc(var(--screen-height) * 0.38)",
      left: "calc(var(--screen-width) * 0.32)",
      width: "calc(var(--screen-width) * 0.26)",
    },
    "item-0": {
      top: "calc(var(--screen-height) * 0.35)",
      left: "calc(var(--screen-width) * 0.6)",
      width: "calc(var(--screen-width) * 0.23)",
    },
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  // when all items are draged into location
  const handleDragEnd = (event) => {
    const { over } = event;
    playDropSound();

    if (over && over.id === "bucket") {
      setDraggedItems((prev) => new Set([...prev, activeId]));
      setBucketImageIndex((prevIndex) => (prevIndex + 1) % sources.length);
    }

    setActiveId(null);

    // Check if all items have been dragged
    if (draggedItems.size === items.length - 1) {
      if (isActive) setSelectorReturnValue("changeFooterText");
      setIsActive(false);
    }
  };

  return (
    <div className="image-matching-container">
      <div className="timer">
        <img src={timerIcon} alt="Timer icon" className="timer-icon" />
        <span className="timer-text">{formatTime(timer)}</span>
      </div>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <ImageDroppableBucket
          id="bucket"
          imageSrc={BACKEND_LINK + sources[bucketImageIndex].media}
        />
        {items.map((item) => (
          <div
            key={item.id}
            className={
              draggedItems.has(item.id)
                ? "invisible-item"
                : "image-draggable-item"
            }
            style={{
              position: "absolute",
              ...itemStyles[item.id],
            }}
          >
            <ImageDraggableItem
              id={item.id}
              imageSrc={BACKEND_LINK + item.imageSrc}
              style={{ width: itemStyles[item.id]?.width }}
            />
          </div>
        ))}
      </DndContext>
    </div>
  );
}

export default TimerImageDragDrop;
