/**
 * Written by Linh
 */

import { useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";
import useSound from "use-sound";

import startRecord from "../../assets/audio/Start-Recording.mp3";
import stopRecord from "../../assets/audio/Stop-Recording.mp3";
import "../../assets/styles/record-with-word-styles.css";

import { BACKEND_LINK } from "../../ultilities/global.links.js";

export default function RecordWithWord({
  isSpanish,
  text,
  setSelectorReturnValue,
}) {
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true,
  });

  const [recordButtonState, setRecordButtonState] = useState(
    "Recording_Start.png"
  );
  const [recordAudioStart] = useSound(startRecord);
  const [recordAudioStop] = useSound(stopRecord);

  const handleRecordClick = () => {
    // TODO: turn this on when user submit the recording later
    setSelectorReturnValue("turnOnNextBtn");

    if (recordButtonState === "Recording_Start.png") {
      setRecordButtonState("Recording_Stop.png");
      recordAudioStart();
      startRecording();
    } else {
      setRecordButtonState("Recording_Start.png");
      recordAudioStop();
      stopRecording();
      // TODO: send media blob to backend
    }
  };

  console.log(text);

  return (
    <div>
      <div className="words-bg">
        <div className="words-container">
          {text.map((word) => (
            <div className="words-selector">{word}</div>
          ))}
        </div>
      </div>

      {/* record btn */}
      <img
        src={`${BACKEND_LINK}ui/${recordButtonState}`}
        onClick={handleRecordClick}
        alt="Record button"
        className="record-word-btn"
      />
    </div>
  );
}
